/* eslint-disable no-unused-vars  */

import React, { useEffect, useState } from "react";
import { BrowserContainer } from "@deloitte-digital-au/dd-breakpoint-container";
import Preloader from "./components/preload/Preloader";
import EmojiBlurb from "./components/hello/EmojiBlurb";
import Portfolio from "./components/experience/Portfolio";
import "./App.scss";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [assetsLoaded, setAssetsLoaded] = useState(0);
  const ASSETS_TO_LOAD = 7;

  const scrollTop = () => {
    window.scroll({
      top: 0,
      // behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!isLoading) {
      scrollTop();
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(assetsLoaded < ASSETS_TO_LOAD);
  }, [assetsLoaded]);

  const onIncrementLoad = () => {
    setAssetsLoaded(assetsLoaded + 1);
  };

  return (
    <BrowserContainer>
      <div className={`app ${isLoading ? "is-loading" : ""}`}>
        <Preloader
          isLoading={isLoading}
          assetsLoaded={assetsLoaded}
          assetsToLoad={ASSETS_TO_LOAD}
        />
        <div className="content">
          <EmojiBlurb isLoading={isLoading} incrementFn={onIncrementLoad} />
          <Portfolio isLoading={isLoading} incrementFn={onIncrementLoad} />
        </div>
      </div>
    </BrowserContainer>
  );
}

export default App;
