/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable no-unused-vars  */

import React, { useEffect, useState, useRef } from "react";

const Preloader = ({ isLoading, assetsLoaded, assetsToLoad }) => {
  const [preloaderText, setPreloaderText] = useState("");
  const [hintClasses, setHintClasses] = useState("is-typed");
  const [hintIndexArr, setHintIndexArr] = useState([]);
  const hintIndexArrRef = useRef([]);
  const isLoadingRef = useRef(true);
  const loaderIntervalRef = useRef(null);

  useEffect(() => {
    hintIndexArrRef.current = hintIndexArr;
    isLoadingRef.current = isLoading;
  });

  const percentage = Math.min(
    100,
    100 * (assetsLoaded / assetsToLoad).toFixed(2)
  );

  const sentences = [
    "Enjoy the elevator music",
    "Your PC will restart several times",
    "Hacking for growth",
    "Travelling through alternate timelines",
    "Waiting for happy hour coffee",
    "Baking some third-party choc cookies",
    "Reading Terms and Conditions for you",
    "Just letting the CSS paint dry",
    "Installing updates 8 of 42",
    "Pushing the pixels in beautiful 4K",
    "Subscribe to premium to skip this",
    "Projecting the TAM",
    "Gamifying your experience",
    "Ordering Patagonia vests",
    "Subscribing you to our seed round",
    "Bootstrapping the MVP",
  ];

  const PRELOADER_PERSIST_MS = 2100; // Delay before preloader messages show

  useEffect(() => {
    loaderIntervalRef.current = setInterval(() => {
      if (!isLoadingRef.current && loaderIntervalRef.current != null) {
        clearInterval(loaderIntervalRef.current);
      }
      pushPreloaderText();
    }, PRELOADER_PERSIST_MS);

    return () => {
      clearInterval(loaderIntervalRef.current);
    };
  }, []);

  const getRandSentence = () => {
    const getRand = (min = 0, max = sentences.length - 1) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    let newRand = getRand();
    while (
      hintIndexArrRef.current.includes(newRand) &&
      hintIndexArrRef.current.length < sentences.length
    ) {
      // console.log(`oops ${newRand} already used`);
      newRand = getRand();
    }
    setHintIndexArr((oldArr) => [...oldArr, newRand]);
    return sentences[newRand];
  };

  const pushPreloaderText = () => {
    setHintClasses("");
    setPreloaderText(getRandSentence());

    setTimeout(() => {
      setHintClasses("is-typed");
    }, PRELOADER_PERSIST_MS - 400);
  };

  return (
    <div className="preloader">
      {isLoading ? (
        <div className="preloader-inner">
          <div className={`hint ${hintClasses}`}>
            <p>
              {preloaderText} <span>.</span> <span>.</span> <span>.</span>
            </p>
          </div>
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div style={{ width: `${percentage}%` }} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Preloader;
