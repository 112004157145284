import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// import ScrollIndicator from "./ScrollIndicator";
import deloitteAusgov from "../../images/ausgov.jpg";
import deloitteEcomm from "../../images/ta.mp4";
import shopify from "../../images/shopify.jpg";
import apple from "../../images/apple.jpg";
import google from "../../images/google.jpg";
import uber from "../../images/uber.mp4";
import rhok from "../../images/rhok.mp4";
import lumos from "../../images/lumos-trailor-compressed.mp4";
import instagram from "../../images/instagram.mp4";
gsap.registerPlugin(ScrollTrigger);

const Portfolio = ({ incrementFn, isLoading }) => {
  const playVideo = (el) => {
    try {
      el && el.play();
    } catch (e) {}
  };

  const pauseVideo = (el) => {
    try {
      el && el.pause();
    } catch (e) {}
  };

  useEffect(() => {
    !isLoading && ScrollTrigger.refresh();
  }, [isLoading]);

  useEffect(() => {
    const timeline = gsap.timeline();

    // Project Titles
    // https://greensock.com/forums/topic/25337-toggleclass-on-elements-entering-viewport-with-scrolltrigger/
    const sectionTitles = document.querySelectorAll(".project-desc > .title");
    sectionTitles.forEach((elem) => {
      timeline.from(elem, {
        lazy: false,
        scrollTrigger: {
          trigger: elem,
          start: "50% 90%",
          end: "top 120px",
          // toggleClass: "is-active",
          onEnter() {
            elem.classList.add("is-active");
          },
          onLeaveBack() {
            elem.classList.remove("is-active");
          },
          toggleActions: "reset none none reset",
          // markers: true,
        },
      });
    });

    // Project Descriptions
    const sectionDesc = document.querySelectorAll(
      ".project-desc > .copy, .js-fade"
    );
    sectionDesc.forEach((elem) => {
      timeline.from(elem, {
        lazy: false,
        scrollTrigger: {
          trigger: elem,
          start: "20% 90%",
          end: "80% 15%",
          // toggleClass: "is-active",
          onEnter() {
            elem.classList.add("is-active");
          },
          onLeaveBack() {
            elem.classList.remove("is-active");
          },
          toggleActions: "reset none none reset",
          // markers: true,
        },
      });
    });

    // Project Media
    const sectionMedia = document.querySelectorAll(
      ".project-media, .img-insights"
    );
    sectionMedia.forEach((elem) => {
      timeline.from(elem, {
        lazy: false,
        scrollTrigger: {
          trigger: elem,
          start: "50% bottom",
          end: "50% 50%",
          scrub: true,
          // toggleActions: "restart pause resume none",
          // markers: true,
        },
        autoAlpha: 0,
      });
    });

    // Video pause
    const videos = document.querySelectorAll(".project-media video");
    videos.forEach((elem) => {
      timeline.from(elem, {
        lazy: false,
        scrollTrigger: {
          trigger: elem,
          start: "top bottom",
          end: "bottom top",
          onToggle: (self) =>
            self.isActive ? playVideo(elem) : pauseVideo(elem),
          toggleActions: "restart none none none",
          // markers: true,
        },
      });
    });
  }, []);

  function scrollToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="portfolio" id="portfolio">
      <div className="portfolio__container">
        <section>
          <div className="section-header">
            <h2 className="section-title">LUMOS</h2>
            <span className="section-title-shadow" aria-hidden="true">
              LUMOS
            </span>
          </div>
          <div className="section-content">
            <div className="project-row flex-center">
              <div className="project-media lumos-gradient">
                <video
                  className="quickview-media"
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="auto"
                  height="350"
                  onCanPlay={() => incrementFn()}
                  src={lumos}
                  type="video/mp4"
                >
                  <source src={lumos} type="video/mp4" />
                </video>
              </div>
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Building Tech Startup</span>
                    <span role="img" aria-label="">
                      🚶🏻‍♂️
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    As the Co-Founder & CTO of LUMOS, we leverage the booming
                    food delivery network with our IoT-enabled digital billboard
                    smart bags, to deliver targeted physical and digital ads in
                    the right place at the right time.
                    <br />
                    <a
                      href="https://spotlumos.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See how we innovate outdoor advertising
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 className="section-title">Deloitte Digital</h2>
            <span className="section-title-shadow" aria-hidden="true">
              Deloitte
              <br />
              Digital
            </span>
          </div>
          <div className="section-content">
            <div className="project-row flex-center">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>myGov & Australia.gov.au</span>
                  </h3>
                  <h3>
                    <span>Digital Transformation</span>
                    <span role="img" aria-label="">
                      🏛
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    As the lead UI Engineer, I helped transform myGov &
                    Australia.gov.au with a brand new experience powered by
                    React, while owning the front end design system across
                    components used by millions of Australian citizens.
                  </p>
                </div>
              </div>
              <div className="project-media">
                <img
                  onLoad={() => incrementFn()}
                  src={deloitteAusgov}
                  alt=""
                  height="500"
                />
              </div>
            </div>
            <div className="project-row flex-center">
              <div className="project-media">
                <video
                  className="quickview-media"
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="auto"
                  height="400"
                  onCanPlayThrough={() => incrementFn()}
                  src={deloitteEcomm}
                  type="video/mp4"
                >
                  <source src={deloitteEcomm} type="video/mp4" />
                </video>
              </div>
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Commerce Design System</span>
                    <span role="img" aria-label="">
                      🛒
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    I built a scalable eCommerce design system with a dynamic
                    brand styleguide, for clients such as The North Face, Kate
                    Spade, Coach & Lacoste, as well as My Muscle Chef & Amart
                    Furniture, now being used by all commerce projects as a
                    scalable accelerator.
                  </p>
                </div>
              </div>
            </div>
            <div className="project-row with-no-media">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Hackity Hack</span>
                    <span role="img" aria-label="">
                      ⛑
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    I actively represented Deloitte Digital as part of the
                    judging panel for hackathons and non-profit community
                    events, such as UTS Startups & Codebar. I also love
                    participating in design & build hackathons!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 className="section-title">Side Projects</h2>
            <span className="section-title-shadow" aria-hidden="true">
              Side Projects
            </span>
          </div>
          <div className="section-content">
            <div className="project-row">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Instagram AR Filters</span>
                    <span role="img" aria-label="">
                      🤳🏻
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    Decided to have some fun with Augmented Reality along with
                    home cooking, to show off my well done steaks & watery
                    dalgona coffees.
                    <br />
                    <a
                      href="https://www.instagram.com/ar/275068510195851/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Try it out chef
                    </a>
                  </p>
                </div>
                <div className="ig-insights js-fade">
                  <dl>
                    <div>
                      <dt>Impressions</dt>
                      <dd>480K+</dd>
                    </div>
                    <div>
                      <dt>Opens</dt>
                      <dd>11K+</dd>
                    </div>
                    <div>
                      <dt>Captures</dt>
                      <dd>2K+</dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="project-media">
                <video
                  className="quickview-media"
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="320"
                  height="auto"
                  onCanPlayThrough={() => incrementFn()}
                  src={instagram}
                  type="video/mp4"
                >
                  <source src={instagram} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="project-row flex-center">
              <div className="project-media">
                <video
                  className="quickview-media"
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="auto"
                  height="400"
                  onCanPlay={() => incrementFn()}
                  src={rhok}
                  type="video/mp4"
                >
                  <source src={rhok} type="video/mp4" />
                </video>
              </div>
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Random Hacks of Kindness</span>
                    <span role="img" aria-label="">
                      🎈
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    Won the hackathon! We provided UX design & Front End dev
                    effort to uplift the existing website of The Humour
                    Foundation, driving engagement from new & regular donors. We
                    also delivered a custom Snapchat face-tracking filter,
                    boosting outreach with the newer generation.
                  </p>
                </div>
              </div>
            </div>
            <div className="project-row flex-center">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Running eCommerce</span>
                    <span role="img" aria-label="">
                      🛍
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    In my spare time I like to dabble in Shopify dropshipping,
                    where I've picked up skills such as SEO, Search, Google
                    Analytics, Google Shopping, Facebook & Instagram
                    advertising, and supplier & customer relationship
                    management.
                  </p>
                </div>
              </div>
              <div className="project-media">
                <img src={shopify} alt="" height="350" />
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div className="section-header">
            <h2 className="section-title">Uber</h2>
            <span className="section-title-shadow" aria-hidden="true">
              Uber
            </span>
          </div>
          <div className="section-content">
            <div className="project-row flex-center">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>UberPool Onboarding</span>
                  </h3>
                  <h3>
                    <span>& Driver Operations</span>
                    <span role="img" aria-label="">
                      👋🏻
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    Developed cross-team agile processes to launch UberPOOL
                    product nationwide. Also facilitated user testing sessions
                    using Invision design prototypes, gathering data to enhance
                    the driver & delivery partner experience.{" "}
                  </p>
                </div>
              </div>
              <div className="project-media">
                <video
                  className="quickview-media"
                  autoPlay
                  muted
                  loop
                  playsInline
                  width="auto"
                  height="460"
                  onCanPlay={() => incrementFn()}
                  src={uber}
                  type="video/mp4"
                >
                  <source src={uber} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 className="section-title">Google</h2>
            <span className="section-title-shadow" aria-hidden="true">
              Google
            </span>
          </div>
          <div className="section-content">
            <div className="project-row">
              <div className="project-media">
                <img src={google} alt="" height="390" />
              </div>
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Designing a new Googler UX</span>
                  </h3>
                  <h3>
                    <span>& Enhancing app for 800+ users</span>
                    <span role="img" aria-label="">
                      🎨
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    I started the internship with the aim of learning &
                    streamlining the Googler user experience across all internal
                    tools & technologies. I also redesigned & improved an
                    internal Angular web application used by over 800 users
                    within Google.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 className="section-title">J.P. Morgan</h2>
            <span className="section-title-shadow" aria-hidden="true">
              J.P. Morgan
            </span>
          </div>
          <div className="section-content">
            <div className="project-row with-no-media">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Robotics Project Management</span>
                  </h3>
                  <h3>
                    <span>& Data Visualisation</span>
                    <span role="img" aria-label="">
                      🤖
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    During my internship at J.P. Morgan, I managed in-house
                    digital products at the Corporate Investment Bank, hosting
                    design workshops on RPA, Machine Learning, Data Analytics &
                    Visualisation across APAC. Nothing I can show here!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 className="section-title">Apple</h2>
            <span className="section-title-shadow" aria-hidden="true">
              Apple
            </span>
          </div>
          <div className="section-content">
            <div className="project-row flex-center with-extra">
              <div className="project-desc">
                <div className="title">
                  <h3>
                    <span>Customer Empathy</span>
                  </h3>
                  <h3>
                    <span>& Piloting all-new workshops</span>
                    <span role="img" aria-label="">
                      💁🏻‍♂️
                    </span>
                  </h3>
                </div>
                <div className="copy">
                  <p>
                    At the flagship Apple Store, it was so enriching to be able
                    to engage with a diverse range of people from different
                    technical & cultural backgrounds every day. I also piloted
                    the initial customer trial of Chinese thematic workshops in
                    Australia.
                  </p>
                </div>
              </div>
              <div className="project-media">
                <img src={apple} alt="" height="380" />
              </div>
            </div>
            <div className="project-desc-extra">
              <p>Life inside the fish bowl - my second iPhone launch!</p>
            </div>
          </div>
        </section>
      </div>
      <div className="portfolio__scrolltop">
        <span className="section-title-shadow" aria-hidden="true">
          Need more?
        </span>
        <h2 className="section-title">Say Hello</h2>
        <button className="chat-suggestion" onClick={scrollToTop}>
          <span>
            <span role="img" aria-label="">
              ☕️
            </span>
            Get in touch
          </span>
        </button>
        <p className="footer-text">hi@jerryyip.me</p>
      </div>
    </div>
  );
};

export default Portfolio;
