import React from "react";
import Typist from "react-typist";

class EmojiBlurb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatDisabled: false,
      blurbHidden: false,
      timeout: null,
    };
    this._makeBubble = this._makeBubble.bind(this);
    this._sayHello = this._sayHello.bind(this);
    this._sayResume = this._sayResume.bind(this);
    this._sayCoffee = this._sayCoffee.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this._destroyBubbles = this._destroyBubbles.bind(this);
  }

  // Generate send/receive chat bubble div
  _makeBubble(direction, message) {
    var bubble;
    if (direction === "send") {
      bubble = '<div class="chat-bubble send">' + message + "</div>";
    } else if (direction === "receive") {
      bubble = '<div class="chat-bubble receive">' + message + "</div>";
    }
    return bubble;
  }

  _animateBubble() {
    const c = this;

    // Disable all buttons
    this.setState({ chatDisabled: true });

    // Fade blurb
    this.blurbText.classList.add("is-hiding");

    // On button press, show bubble
    setTimeout(() => {
      // Show bubble
      c.chatBubbles
        .querySelectorAll(".chat-bubble.send")[0]
        .classList.add("is-showing");
      const receiveDelay = 800 + 1000 * Math.random();
      setTimeout(() => {
        c.chatBubbles
          .querySelectorAll(".chat-bubble.receive")[0]
          .classList.add("is-showing");
      }, receiveDelay);

      // Set timeout reset
      setTimeout(() => {
        // Reset
        c._destroyBubbles();
        c.blurbText.classList.remove("is-hiding");
        this.setState({ chatDisabled: false });
      }, 4500);
    }, 100);
  }

  _destroyBubbles() {
    const bubbleSent = this.chatBubbles.querySelectorAll(".chat-bubble.send");
    const bubbleReceived = this.chatBubbles.querySelectorAll(
      ".chat-bubble.receive"
    );
    bubbleSent[0].classList.add("is-hiding");
    bubbleReceived[0].classList.add("is-hiding");

    setTimeout(() => {
      bubbleSent.forEach((b) => {
        b.remove();
      });
      bubbleReceived.forEach((b) => {
        b.remove();
      });
    }, 200);
  }

  _sayHello() {
    const msgToSend = this._makeBubble("send", "Hey there, Jerry!");
    const msgToReceive = this._makeBubble(
      "receive",
      "You found me! Thanks for stopping by."
    );

    this.chatBubbles.innerHTML += msgToSend;
    this.chatBubbles.innerHTML += msgToReceive;

    this._animateBubble();
  }

  _sayResume() {
    const msgToSend = this._makeBubble(
      "send",
      "Can I get a copy of your resume?"
    );
    const msgToReceive = this._makeBubble(
      "receive",
      // "Of course! Let me get that for you..."
      "Of course! Just reach out anywhere."
    );

    this.chatBubbles.innerHTML += msgToSend;
    this.chatBubbles.innerHTML += msgToReceive;

    this._animateBubble();
  }

  _sayCoffee() {
    const msgToSend = this._makeBubble("send", "Let's get a coffee, Jerry!");
    const msgToReceive = this._makeBubble(
      "receive",
      "Sure! Reach me on <a href='https://www.linkedin.com/in/jerrycyyip/' target='_blank' rel='noreferrer noopener'>LinkedIn</a> or <a href='mailto:hi@jerryyip.me?subject=Hello!' target='_blank' rel='noreferrer noopener'>Email</a>. Let me know how I can help!"
    );

    this.chatBubbles.innerHTML += msgToSend;
    this.chatBubbles.innerHTML += msgToReceive;

    this._animateBubble();
  }

  // Sticky nav on debounce
  _onScroll() {
    // If there's a timer, cancel it
    if (this.state.timeout) {
      window.cancelAnimationFrame(this.state.timeout);
    }

    // Setup the new requestAnimationFrame()
    this.setState({
      timeout: window.requestAnimationFrame(() => {
        if (
          window.scrollY >
          document.getElementById("portfolio").offsetTop - 50
        ) {
          this.setState({ blurbHidden: true });
        } else {
          this.setState({ blurbHidden: false });
        }
      }),
    });
  }

  componentDidMount() {
    this.chatBubbles = document.getElementById("chat-bubbles");
    this.blurbText = document.getElementById("blurb-text");
    document.getElementById("hello-video").play();
    document.getElementById("sticky-video").play();
    this.bubbleTimeout = null;

    // Sticky
    window.addEventListener("scroll", this._onScroll, { passive: true });

    // CV
    // const isMobile =
    //   /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    //     navigator.userAgent.toLowerCase()
    //   );
    // const pdfUrl =
    //   "https://www.dropbox.com/s/rwjprak2zqbhpcj/Jerry%20Yip%20-%20Resume%20CV.pdf?dl=1";
    // if (isMobile) {
    //   document.querySelector("#btn-cv-download").onclick = () => {
    //     window.open(pdfUrl, "_blank");
    //   };
    // } else {
    //   document.querySelector("#btn-cv-download").onclick = () => {
    //     setTimeout(() => {
    //       window.open(pdfUrl);
    //     }, 3300);
    //   };
    // }
  }

  render() {
    const { incrementFn, isLoading } = this.props;

    return (
      <div className="blurb-wrapper">
        <div
          className={`blurb-sticky ${
            this.state.blurbHidden ? "is-visible" : ""
          }`}
          id="blurb-sticky"
        >
          <div className="sticky-content">
            <video
              id="sticky-video"
              autoPlay
              muted
              loop
              playsInline
              width="auto"
              height="100%"
              src={require("../../images/memoji.mp4")}
              type="video/mp4"
            >
              <source
                src={require("../../images/memoji.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="sticky-message">
              <p>
                {this.state.blurbHidden && (
                  <Typist
                    cusor={{
                      show: true,
                      blink: true,
                      hideWhenDone: true,
                      hideWhenDoneDelay: 1000,
                    }}
                    startDelay={300}
                    avgTypingDelay={50}
                  >
                    It's nice to meet you.
                    <Typist.Backspace count={22} delay={8000} />
                    <Typist.Delay ms={500} />
                    Just dive right in!
                    <Typist.Backspace count={19} delay={8000} />
                    <Typist.Delay ms={500} />
                    Like what you see?
                    <Typist.Backspace count={18} delay={8000} />
                    <Typist.Delay ms={500} />
                    How can I help?
                    <Typist.Backspace count={15} delay={8000} />
                    <Typist.Delay ms={500} />
                    Let's get in touch!
                  </Typist>
                )}
              </p>
            </div>
            <div className="sticky-contact">
              <a
                href="https://www.linkedin.com/in/jerrycyyip/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={require("../../images/icon-linkedin-black.svg")}
                  alt="Jerry on LinkedIn"
                />
              </a>
              <a href="mailto:hi@jerryyip.me?subject=Hello!">
                <img
                  src={require("../../images/icon-mail-black.svg")}
                  alt="Email Jerry"
                />
              </a>
              {/* <a
                href="https://www.behance.net/jerryyip"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={require("../../images/icon-behance-black.svg")}
                  alt="Jerry on Behance"
                />
              </a> */}
              <a
                href="https://www.goodreads.com/jerryyip"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={require("../../images/icon-goodreads-black.png")}
                  alt="Jerry on Goodreads"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="blurb" id="blurb">
          <div className="blurb__video">
            <video
              id="hello-video"
              autoPlay
              muted
              loop
              playsInline
              width="300"
              height="300"
              onCanPlayThrough={() => incrementFn()}
              src={require("../../images/memoji.mp4")}
              type="video/mp4"
            >
              <source
                src={require("../../images/memoji.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="blurb__info">
            <div className="blurb__text" id="blurb-text">
              <h1>
                Hello! I'm <span>Jerry</span>.
              </h1>
              <p>
                {!this.state.blurbHidden && !isLoading && (
                  <Typist
                    cusor={{
                      show: true,
                      blink: true,
                      hideWhenDone: false,
                      hideWhenDoneDelay: 1000,
                    }}
                    startDelay={600}
                    avgTypingDelay={60}
                  >
                    I design, code, & tinker.
                    <Typist.Backspace count={23} delay={2500} />
                    <Typist.Delay ms={500} />
                    experiment by making things.
                    <Typist.Backspace count={28} delay={2500} />
                    <Typist.Delay ms={500} />
                    design for humans.
                    <Typist.Backspace count={11} delay={2500} />
                    to make you go Ooooh
                    <Typist.Backspace count={27} delay={2500} />
                    <Typist.Delay ms={500} />
                    code to make things easier.
                    <Typist.Backspace count={19} delay={2000} />
                    solve problems better.
                    <Typist.Backspace count={16} delay={2000} />
                    for good.
                    <Typist.Backspace count={23} delay={2500} />
                    <Typist.Delay ms={500} />
                    get inspired by all disciplines.
                    <Typist.Backspace count={32} delay={2000} />
                    <Typist.Delay ms={500} />
                    try stuff out just for fun!
                    <Typist.Backspace count={27} delay={2500} />
                    <Typist.Delay ms={500} />
                    think you should reach out{" "}
                    <span role="img" aria-label="">
                      😊
                    </span>
                    <Typist.Backspace count={29} delay={10000} />
                    <Typist.Delay ms={500} />
                    design, code, & tinker.
                  </Typist>
                )}
              </p>
              <p>UX Engineering at heart. Building creative things at LUMOS.</p>
            </div>
            <div className="blurb__chat">
              <div className="chat-bubbles" id="chat-bubbles"></div>
              <div className="chat-suggestion-bar">
                <button
                  className="chat-suggestion"
                  onClick={this._sayHello}
                  disabled={this.state.chatDisabled}
                >
                  <span>
                    <span role="img" aria-label="hello">
                      👋
                    </span>
                    Hello!
                  </span>
                </button>
                <button
                  className="chat-suggestion"
                  onClick={this._sayResume}
                  disabled={this.state.chatDisabled}
                  id="btn-cv-download"
                >
                  <span>
                    <span role="img" aria-label="resume">
                      📃
                    </span>
                    Resume CV
                  </span>
                </button>
                <button
                  className="chat-suggestion"
                  onClick={this._sayCoffee}
                  disabled={this.state.chatDisabled}
                >
                  <span>
                    <span role="img" aria-label="get in touch">
                      ☕️
                    </span>
                    Get in touch
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmojiBlurb;
